import './App.css'
import Home from './containers/Home'

function App() {
  return (
    <div className="App">
      <Home />
    </div>
  )
}

export default App
